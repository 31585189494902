import { template as template_16a69713b89c4f249336e66aa19ae231 } from "@ember/template-compiler";
const FKLabel = template_16a69713b89c4f249336e66aa19ae231(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
