import { template as template_524258a8e30f4de0a8968b903a4a7ca2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_524258a8e30f4de0a8968b903a4a7ca2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
